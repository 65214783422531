<template>
    <div class="input-wrapper">
        <div class="label">{{ label }}</div>
        <div class="custom-select">
            <div class="selected">
                {{ (selected && selected.label) || "請選擇" }}
            </div>
            <select v-model="selected" @change="onChange(selected)">
                <option
                    v-for="(option, i) in options"
                    :key="`option${i}`"
                    :value="option"
                >
                    {{ option.label }}
                </option>
            </select>
        </div>
        <InputWrapper
            v-if="selected && selected.value === 'other'"
            @change="onChangeOther"
        />
    </div>
</template>

<script>
import InputWrapper from "@/components/Game/Wrappers/InputWrapper.vue";

export default {
    components: { InputWrapper },
    props: {
        label: String,
        options: Array,
    },
    data() {
        return {
            selected: undefined,
        };
    },
    methods: {
        onChange(val) {
            this.selected = val;
            if (val.value === "other") {
                this.$emit("delete", this.label);
            } else this.$emit("change", { [this.label]: this.selected.value });
        },
        onChangeOther(val) {
            this.$emit("change", { [this.label]: val.default });
        },
    },
};
</script>

<style lang="scss" scoped>
.label {
    margin-bottom: 3px;
    .required {
        color: #ed6f6f;
    }
}
.custom-select {
    position: relative;
    height: 35px;
    background-color: #ffffff;
    box-sizing: border-box;
    border: 1px solid #979797;
    border-radius: 2px;
    display: flex;
    .selected {
        width: 100%;
        height: 100%;
        padding: 10px;
        display: flex;
        // justify-content: center;
        align-items: center;
        font-weight: bold;
        text-align: center;
    }
    select {
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
        &:focus {
            border: 0;
            outline: 0px;
        }
        &:focus-visible {
            outline: 0px;
        }
    }
}
</style>
