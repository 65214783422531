<template>
    <div class="input-wrapper">
        <div class="label">{{ label === "default" ? "" : label }}</div>
        <div class="input" :class="{ error: isError }">
            <input
                v-model="data"
                :placeholder="placeholder"
                @change="onChange"
            />
            <transition name="fade" mode="out-in">
                <div v-if="isError" ref="error" class="error">
                    {{ errorMsg }}
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        rules: Object,
        label: {
            type: String,
            default: "default",
        },
        placeholder: String,
    },
    data() {
        return {
            data: undefined,
            isInteracted: false,
            isError: false,
            errorMsg: undefined,
        };
    },
    methods: {
        onChange() {
            this.isInteracted = true;
            if (this.rules) this.onCheckRule();
            this.$emit("change", { [this.label]: this.data });
        },
        onCheckRule() {
            if (!this.data && this.rules.required) {
                this.isError = true;
                this.errorMsg = "此欄位為必填";
            } else if (this.data && !this.data.match(this.rules.regex)) {
                this.isError = true;
                this.errorMsg = this.rules.error;
            } else this.isError = false;
        },
        validate() {
            this.onChange();
            return !this.rules || (this.rules && !this.isError);
        },
    },
};
</script>

<style lang="scss" scoped>
.label {
    margin-bottom: 3px;
    .required {
        color: #ed6f6f;
    }
}
.input {
    position: relative;
    width: 100%;
    height: 35px;
    background-color: #ffffff;
    box-sizing: border-box;
    border: 1px solid #979797;
    border-radius: 2px;
    &.error {
        border: 1px solid #ed6f6f;
    }
    input {
        font-size: 1em;
        width: 100%;
        height: 100%;
        padding: 10px;
        box-sizing: border-box;
        &::placeholder {
            opacity: 0.6; /* Firefox */
        }
    }
    .error {
        font-size: 0.8em;
        color: #ed6f6f;
        position: absolute;
        bottom: 0;
        transform: translateY(110%);
    }
}

input,
label,
select,
button,
textarea {
    margin: 0;
    border: 0;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
    background: none;
    line-height: 1;

    /* Browsers have different default form fonts */
    font-family: Arial;
}

/* Remove the stupid outer glow in Webkit */
input:focus {
    outline: 0;
}

/* Box Sizing Reset
-----------------------------------------------*/

/* All of our custom controls should be what we expect them to be */
input,
textarea {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

/* These elements are usually rendered a certain way by the browser */
button,
input[type="reset"],
input[type="button"],
input[type="submit"],
input[type="checkbox"],
input[type="radio"],
select {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
/* Button Controls
-----------------------------------------------*/

input[type="checkbox"],
input[type="radio"] {
    width: 13px;
    height: 13px;
}

/* Turn off the recent search for webkit. It adds about 15px padding on the left */
::-webkit-search-decoration {
    display: none;
}

/* Buttons
-----------------------------------------------*/

button,
input[type="reset"],
input[type="button"],
input[type="submit"] {
    /* Fix IE7 display bug */
    overflow: visible;
    width: auto;
}

/* IE8 and FF freak out if this rule is within another selector */
::-webkit-file-upload-button {
    padding: 0;
    border: 0;
    background: none;
}

/* Textarea
-----------------------------------------------*/

textarea {
    /* Move the label to the top */
    vertical-align: top;

    /* Turn off scroll bars in IE unless needed */
    overflow: auto;
}

/* Selects
-----------------------------------------------*/

select[multiple] {
    /* Move the label to the top */
    vertical-align: top;
}
</style>
