<template>
    <div class="input-wrapper">
        <div class="label">{{ label }}</div>
        <div class="description">{{ description }}</div>
        <div class="radio-group">
            <div
                v-for="(option, i) in options"
                :key="`radio${i}`"
                class="container"
                @click="onChange(option.value)"
            >
                <input v-model="selected" type="radio" :value="option.value" />
                <span>{{ option.label }}</span>
                <span class="checkmark"></span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        label: String,
        description: String,
        options: Array,
    },
    data() {
        return {
            selected: this.options[0].value,
        };
    },
    created() {
        this.$emit("change", { [this.label]: this.selected });
    },
    methods: {
        onChange(val) {
            this.selected = val;
            this.$emit("change", { [this.label]: this.selected });
        },
    },
};
</script>

<style lang="scss" scoped>
.label {
    margin-bottom: 3px;
    .required {
        color: #ed6f6f;
    }
}
.description {
    margin-bottom: 3px;
}
.radio-group {
    display: flex;
    justify-content: space-between;
}

.container {
    display: block;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 18px;
    width: 18px;
    background-color: #fff;
    border: 1px solid #ed6f6f;
    border-radius: 50%;
}
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 50%;
    border-radius: 50%;
    background: #ed6f6f;
}
</style>
