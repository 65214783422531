export default {
  gender: [
    {
      label: "男生",
      value: "男生",
    },
    {
      label: "女生",
      value: "女生",
    },
    {
      label: "其他",
      value: "其他",
    },
  ],
  age: [
    {
      label: "0 - 14歲",
      value: "0 - 14歲",
    },
    {
      label: "15 - 24歲",
      value: "15 - 24歲",
    },
    {
      label: "25 - 34歲",
      value: "25 - 34歲",
    },
    {
      label: "35 - 44 歲",
      value: "35 - 44 歲",
    },
    {
      label: "45 - 54 歲",
      value: "45 - 54 歲",
    },
    {
      label: "55 - 64歲",
      value: "55 - 64歲",
    },
    {
      label: "65 - 74歲",
      value: "65 - 74歲",
    },
    {
      label: "75歲以上",
      value: "75歲以上",
    },
  ],
  job: [
    {
      label: "學生",
      value: "學生",
    },
    {
      label: "教師",
      value: "教師",
    },
    {
      label: "法律業",
      value: "法律業",
    },
    {
      label: "藝術創作者",
      value: "藝術創作者",
    },
    {
      label: "建築與工程",
      value: "建築與工程",
    },
    {
      label: "媒體相關",
      value: "媒體相關",
    },
    {
      label: "家庭主婦",
      value: "家庭主婦",
    },
    {
      label: "公務員",
      value: "公務員",
    },
    {
      label: "農林漁牧業",
      value: "農林漁牧業",
    },
    {
      label: "科技業",
      value: "科技業",
    },
    {
      label: "服務業",
      value: "服務業",
    },
    {
      label: "運輸業",
      value: "運輸業",
    },
    {
      label: "其他（請填寫）",
      value: "other",
    },
  ],
  heardFrom: [
    {
      label: "台中市衛生局-健康小衛星 臉書粉絲團",
      value: "台中市衛生局-健康小衛星 臉書粉絲團",
    },
    {
      label: "LINE廣告",
      value: "LINE廣告",
    },
    {
      label: "親朋好友分享",
      value: "親朋好友分享",
    },
    {
      label: "其他（自由填寫）",
      value: "other",
    },
  ],
  whyJoin: [
    {
      label: "想了解更多心理健康知識",
      value: "想了解更多心理健康知識",
    },
    {
      label: "獎品吸引人",
      value: "獎品吸引人",
    },
    {
      label: "活動視覺好看",
      value: "活動視覺好看",
    },
    {
      label: "沒有特別原因，只是想玩玩看",
      value: "沒有特別原因，只是想玩玩看",
    },
  ],
  favorite: [
    {
      label: "關卡0:心情溫度計",
      value: "關卡0:心情溫度計",
    },
    {
      label: "關卡1:心理健康基礎問答",
      value: "關卡1:心理健康基礎問答",
    },
    {
      label: "關卡2:心理健康進階問答",
      value: "關卡2:心理健康進階問答",
    },
    {
      label: "關卡3:1925安心專線GOGOGO",
      value: "關卡3:1925安心專線GOGOGO",
    },
    {
      label: "關卡4:心理健康對對碰！",
      value: "關卡4:心理健康對對碰！",
    },
    {
      label: "關卡5:抗憂鬱食物記憶大考驗",
      value: "關卡5:抗憂鬱食物記憶大考驗",
    },
  ],
  howMuch: [
    {
      label: "1",
      value: 1,
    },
    {
      label: "2",
      value: 2,
    },
    {
      label: "3",
      value: 3,
    },
    {
      label: "4",
      value: 4,
    },
    {
      label: "5",
      value: 5,
    },
  ],
  question1: [
    {
      label: "安心專線1925",
      value: "安心專線1925",
    },
    {
      label: "張老師中心1980 ",
      value: "張老師中心1980 ",
    },
    {
      label: "生命線1995",
      value: "生命線1995",
    },
    {
      label: "以上皆是",
      value: "以上皆是",
    },
  ],
  question2: [
    {
      label: "瘋子",
      value: "瘋子",
    },
    {
      label: "神經病 ",
      value: "神經病 ",
    },
    {
      label: "精神分裂症",
      value: "精神分裂症",
    },
    {
      label: "思覺失調症",
      value: "思覺失調症",
    },
  ],
  question3: [
    {
      label: "免費定點諮詢服務",
      value: "免費定點諮詢服務",
    },
    {
      label: "長者憂鬱篩檢 ",
      value: "長者憂鬱篩檢 ",
    },
    {
      label: "心理師老人到宅諮詢服務",
      value: "心理師老人到宅諮詢服務",
    },
    {
      label: "以上皆是",
      value: "以上皆是",
    },
  ],
  question4: [
    {
      label: "1問",
      value: "1問",
    },
    {
      label: "2應",
      value: "2應",
    },
    {
      label: "3轉介",
      value: "3轉介",
    },
    {
      label: "1、2、3皆是",
      value: "1、2、3皆是",
    },
    {
      label: "不用幫忙，他會自己調適好",
      value: "不用幫忙，他會自己調適好",
    },
  ],

}