<template>
    <div
        class="form"
        :style="cssStyle"
        :class="{ 'is-submit': isFormSubmitted }"
    >
        <div
            v-if="isFormSubmitted !== undefined && !isFormSubmitted"
            class="inner"
        >
            <div class="header">問卷填寫</div>
            <RadioWrapper label="性別" :options="gender" @change="saveData" />
            <SelectWrapper label="年齡" :options="age" @change="saveData" />
            <SelectWrapper
                label="職業"
                :options="job"
                @change="saveData"
                @delete="onDelete"
            />
            <SelectWrapper
                label="從哪裡得知活動訊息"
                :options="heardFrom"
                @change="saveData"
                @delete="onDelete"
            />
            <SelectWrapper
                label="為什麼想參加闖關活動"
                :options="whyJoin"
                @change="saveData"
            />
            <SelectWrapper
                label="你最喜歡哪個關卡"
                :options="favorite"
                @change="saveData"
            />
            <RadioWrapper
                label="參與闖關活動後，我更了解心理健康知識"
                description="［1=非常不同意，5=非常同意］"
                :options="howMuch"
                @change="saveData"
            />
            <RadioWrapper
                label="未來我會想再了解更多心理健康相關的內容"
                description="［1=非常不同意，5=非常同意］"
                :options="howMuch"
                @change="saveData"
            />
            <SelectWrapper
                label="心情不好，想要找人談談，可以撥打下列哪支電話？"
                :options="question1"
                @change="saveData"
            />
            <SelectWrapper
                label="下列何者是正確的精神疾病用語？"
                :options="question2"
                @change="saveData"
            />
            <SelectWrapper
                label="下列何者是臺中市社區心理衛生中心業務內容？"
                :options="question3"
                @change="saveData"
            />
            <SelectWrapper
                label="如果發現親朋好友心情不好，可以怎麼幫他？"
                :options="question4"
                @change="saveData"
            />
            <div>
                送出表單前請再次確認所有資訊皆正確填寫，經送出即無法重新修改
            </div>
            <InputWrapper
                label="參加者姓名"
                placeholder=""
                @change="saveData"
            />
            <InputWrapper
                label="參加者聯絡電話"
                placeholder=""
                @change="saveData"
            />
            <InputWrapper
                label="參加者Email"
                placeholder=""
                @change="saveData"
            />
            <InputWrapper
                label="獎品收件地址"
                placeholder=""
                @change="saveData"
            />
            <TextareaWrapper
                label="歡迎分享參加闖關活動的心得，或是活動可以改善的地方"
                type="textarea"
                placeholder=""
                @change="saveData"
            />
            <div>
                感謝您參與「心理健康一起SMILE」闖關活動，得獎名單將於台中市衛生局-健康小衛星
                臉書粉絲團公告，記得要隨時關注我們喔！
            </div>
            <div class="button-set">
                <div class="submit" @click="onSubmit">送出</div>
                <div class="share" @click="onShare">分享</div>
            </div>
        </div>
        <div v-else-if="isFormSubmitted !== undefined" class="is-submit">
            您已填寫問卷
            <div class="button-set">
                <div class="share" @click="onShare">分享</div>
            </div>
        </div>
        <transition name="fade" mode="out-in">
            <Loading v-if="isLoading" />
        </transition>
    </div>
</template>

<script>
import liff from "@line/liff";
import _ from "lodash";
import { mapState } from "vuex";
import { updateForm, isFormExist } from "@/db";

import CONFIG from "@/config/formQuestion";
import InputWrapper from "@/components/Game/Wrappers/InputWrapper.vue";
import TextareaWrapper from "@/components/Game/Wrappers/TextareaWrapper.vue";
import SelectWrapper from "@/components/Game/Wrappers/SelectWrapper.vue";
import RadioWrapper from "@/components/Game/Wrappers/RadioWrapper.vue";
import Loading from "@/components/Game/Loading.vue";

export default {
    components: {
        InputWrapper,
        TextareaWrapper,
        SelectWrapper,
        RadioWrapper,
        Loading,
    },
    data() {
        return {
            gender: CONFIG.gender,
            age: CONFIG.age,
            job: CONFIG.job,
            heardFrom: CONFIG.heardFrom,
            whyJoin: CONFIG.whyJoin,
            favorite: CONFIG.favorite,
            howMuch: CONFIG.howMuch,
            question1: CONFIG.question1,
            question2: CONFIG.question2,
            question3: CONFIG.question3,
            question4: CONFIG.question4,

            submit: {},
            isFormSubmitted: undefined,

            isLoading: false,
        };
    },
    computed: {
        ...mapState({
            progress: (state) => state.progress,
            user: (stage) => stage.user,
        }),
        encodeURI() {
            return encodeURIComponent(process.env.VUE_APP_SHARE_URL);
        },
        shareUrl() {
            const CONTENT = "分享好友一起玩";
            return `https://line.me/R/msg/text/?${CONTENT} ${this.encodeURI}`;
        },
        cssStyle() {
            return `--navbar-height: ${this.$root.navbarHeight}px`;
        },
    },
    async created() {
        try {
            this.isLoading = true;
            if (this.user.userId)
                this.isFormSubmitted = await isFormExist(this.user.userId);

            let progressNum = 0;
            _.forEach(this.progress, () => {
                progressNum += 1;
            });
            if (progressNum !== 6) this.$router.replace({ name: "Game_Map" });
        } catch (e) {
            console.log(e);
        } finally {
            this.isLoading = false;
        }
    },
    methods: {
        saveData(data) {
            this.submit = {
                ...this.submit,
                ...data,
            };
        },
        async onSubmit() {
            if (_.size(this.submit) === 17) {
                try {
                    this.isLoading = true;
                    await updateForm(this.user.userId, this.submit);
                    alert("感謝您的填寫");
                    this.$router.replace({ name: "Game_Map" });
                } catch (e) {
                    console.log(e);
                } finally {
                    this.isLoading = false;
                }
            } else alert("尚有欄位未填妥");
        },
        onDelete(label) {
            if (this.submit[label]) delete this.submit[label];
        },
        onShare() {
            liff.openWindow({
                url: this.shareUrl,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
$navbar-height: var(--navbar-height);
.form {
    width: 100%;
    max-width: 414px;
    margin: auto;
    padding: 30px 0 50px;
    min-height: calc(100vh - var(--navbar-height));
    &.is-submit {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .is-submit {
        font-size: 25px;
    }
    .inner {
        width: 90%;
        margin: auto;
        > * {
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .header {
        text-align: center;
        margin-bottom: 10px;
    }
    .button-set {
        max-width: 250px;
        margin: 50px auto 0;
        > * {
            color: #fff;
            cursor: pointer;
            display: block;
            white-space: nowrap;
            font-size: 26px;
            border-radius: 7px;
            padding: 10px 30px;
            text-align: center;
        }
        .submit {
            background-color: #ed6f6f;
            box-shadow: -10px 10px 0px 1px #cc5d5d;
        }
        .share {
            margin-top: 30px;
            background-color: #6fd1ed;
            box-shadow: -10px 10px 0px 1px #165492;
        }
    }
}
</style>
